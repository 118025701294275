import { graphql, Link, useStaticQuery } from 'gatsby';

import { Hero } from '../components/Layout/Hero';
import {
  storeLocale,
  getStoredLocale,
  getSecondaryLangs,
  findSecondaryLang,
  isDefaultStored,
  isSecondaryStored,
} from '../functions/localeUtils';
import { getPreferredLocale } from '../functions/getPreferredLocale';
import { isSSR } from '../functions/isSSR';
import { NotFoundPageHead } from '../components/Head/NotFoundPageHead';
import { useLocales } from '../hooks/useLocales';

const AR90PA = () => {
    return (
      <div 
      dangerouslySetInnerHTML={{
        __html:`
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
<stripe-pricing-table pricing-table-id="prctbl_1Qt9deLaD7e4ROKQHJdJvFu0"
publishable-key="pk_live_51LtJ6rLaD7e4ROKQv2huypXAkfDUL1NQnKXUzibSoqpaTWms9GN8FCP3aUMd9bicgaMkXG6c7nI6azILNVoMR4IC00i5jdwIyo">
</stripe-pricing-table>
        `
      }}
      />
    );
};

export default AR90PA;
